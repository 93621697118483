import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from "@angular/forms";
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRadioModule } from '@angular/material/radio';

import { CommonHeaderModule } from '../common-header/common-header.module';
import { ComponentsModule } from '../components/components.module';
import { SharedModule } from '../shared/shared.module';
import { SchedulesModule } from '../schedules/schedules.module';

import { CanvaButtonComponent } from './components/canva-button/canva-button.component';
import { BlueprintService } from './services/blueprint.service';

import { TemplateEditorFooterComponent } from './components/template-editor-footer/template-editor-footer.component';
import { TemplateEditorComponent } from './components/template-editor/template-editor.component';
import { TemplateEditorToolbarComponent } from './components/template-editor-toolbar/template-editor-toolbar.component';
import { TemplateAttributeEditorComponent } from './components/template-attribute-editor/template-attribute-editor.component';
import { TemplateEditorPreviewHolderComponent } from './components/template-editor-preview-holder/template-editor-preview-holder.component';
import { TemplateAttributeListComponent } from './components/template-attribute-list/template-attribute-list.component';
import { TemplateAttributeComponentsComponent } from './components/template-attribute-components/template-attribute-components.component';
import { TemplateComponentComponent } from './components/template-component/template-component.component';
import { TemplateShareComponent } from './template-components/template-share/template-share.component';

import { EncodeLinkPipe } from './pipes/encode-link.pipe';
import { ComponentUtilsService } from './template-components/services/component-utils.service';
import { WeatherComponent } from './template-components/weather/weather.component';
import { TimeDateComponent } from './template-components/time-date/time-date.component';
import { StorageSelectorComponent } from './template-components/storage-selector/storage-selector.component';
import { BrandingComponent } from './template-components/branding/branding.component';
import { BrandingColorsComponent } from './template-components/branding-colors/branding-colors.component';
import { SchedulesComponent } from './template-components/schedules/schedules.component';
import { FinancialComponent } from './template-components/financial/financial.component';
import { ImageComponent } from './template-components/image/image.component';
import { SlidesComponent } from './template-components/slides/slides.component';
import { TextComponent } from './template-components/text/text.component';
import { VideoComponent } from './template-components/video/video.component';
import { RssComponent } from './template-components/rss/rss.component';
import { CounterComponent } from './template-components/counter/counter.component';
import { ColorsComponent } from './template-components/colors/colors.component';
import { TwitterComponent } from './template-components/twitter/twitter.component';
import { PresentationSelectorComponent } from './template-components/presentation-selector/presentation-selector.component';
import { PlaylistComponent } from './template-components/playlist/playlist.component';
import { HtmlComponent } from './template-components/html/html.component';
import { EmptyFileListComponent } from './components/empty-file-list/empty-file-list.component';
import { FileEntryComponent } from './components/file-entry/file-entry.component';
import { BasicUploaderComponent } from './components/basic-uploader/basic-uploader.component';
import { UIRouterModule } from '@uirouter/angular';
import { routes } from './template-editor.routes';
import { ViewportComponent } from './viewport/viewport.component';
import { CanvaTypePickerComponent } from './components/canva-type-picker/canva-type-picker.component';
import { CanvaComponent } from './template-components/canva/canva.component';
import { FolderComponent } from './template-components/folder/folder.component';
import { MediaSelectorComponent } from './template-components/media-selector/media-selector.component';
import { GooglePhotosComponent } from './template-components/google-photos/google-photos.component';
import { YouTubeComponent } from './template-components/youtube/youtube.component';
import { CalendarComponent } from './template-components/calendar/calendar.component';
import { TemplateEditorUtilsService } from './services/template-editor-utils.service';
import { DataTableComponent } from './template-components/data-table/data-table.component';
import { WebPageComponent } from './template-components/web-page/web-page.component';
import { PowerBIComponent } from './template-components/powerbi/powerbi.component';
import { DocumentComponent } from './template-components/document/document.component';
import { OnedriveFilePickerComponent } from './components/onedrive-file-picker/onedrive-file-picker.component';
import { SocialMediaPostsComponent } from './template-components/social-media-posts/social-media-posts.component';

import { StorageModule } from '../storage/storage.module';
import { PlaylistItemSelectorComponent } from './template-components/playlist-item-selector/playlist-item-selector.component';
import { StockLibraryComponent } from './template-components/stock-library/stock-library.component';
import { PurchaseModule } from '../purchase/purchase.module';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  imports: [
    CommonModule,
    UIRouterModule.forChild(routes),

    CommonHeaderModule,
    ComponentsModule,
    SharedModule,
    FormsModule,
    CodemirrorModule,
    BsDropdownModule,
    PopoverModule.forRoot(),
    PurchaseModule,
    SchedulesModule,
    StorageModule,
    MatMenuModule,
    MatTooltipModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatCheckboxModule
  ],
  declarations: [
    CanvaButtonComponent,
    TemplateEditorFooterComponent,
    TemplateEditorComponent,
    TemplateEditorToolbarComponent,
    TemplateAttributeEditorComponent,
    TemplateEditorPreviewHolderComponent,
    TemplateAttributeListComponent,
    TemplateAttributeComponentsComponent,
    TemplateComponentComponent,
    EncodeLinkPipe,
    WeatherComponent,
    TimeDateComponent,
    StorageSelectorComponent,
    BrandingComponent,
    BrandingColorsComponent,
    SchedulesComponent,
    FinancialComponent,
    ImageComponent,
    SlidesComponent,
    TextComponent,
    VideoComponent,
    RssComponent,
    CounterComponent,
    ColorsComponent,
    TwitterComponent,
    PresentationSelectorComponent,
    PlaylistComponent,
    HtmlComponent,
    EmptyFileListComponent,
    FileEntryComponent,
    BasicUploaderComponent,
    ViewportComponent,
    CanvaTypePickerComponent,
    CanvaComponent,
    FolderComponent,
    MediaSelectorComponent,
    TemplateShareComponent,
    GooglePhotosComponent,
    YouTubeComponent,
    CalendarComponent,
    DataTableComponent,
    WebPageComponent,
    PlaylistItemSelectorComponent,
    StockLibraryComponent,
    PowerBIComponent,
    DocumentComponent,
    OnedriveFilePickerComponent,
    SocialMediaPostsComponent,
  ]
})
export class TemplateEditorModule {
  //workaround for including downgraded components into build files
  //https://github.com/angular/angular/issues/35314#issuecomment-584821399
  static entryComponents = [];
  static providers = [BlueprintService, ComponentUtilsService, TemplateEditorUtilsService];
}
